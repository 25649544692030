import moment from 'moment';
import 'moment-timezone';
import Topbar from '@/components/topbar/topbar_component.vue';
import Navbar from '@/components/navbar/navbar_component.vue';
import Actions from '@/components/Actions/actions_component.vue';
import InstantMeet from '@/components/InstantMeetDetails/instant_meeting_component.vue';
import SearchMember from '@/components/search_member/search_member_component.vue';
import Popover from '@/components/popovers/popover_component.vue';
import Loader from '@/components/Loaders/loader_component.vue';
import ChangeLogView from '@/components/changelog/changelog_component.vue';


export default{
    name : 'InstantMeeting',
    components:{
        Topbar,
        Navbar,
        SearchMember,
        Actions,
        InstantMeet,
        Popover,
        Loader,
        ChangeLogView
    },
    data(){
        return{
            open_popup_type:'',
            members_div_open : true,
            risk_div_open :false,
            hover_index : 0,
            minutes_index : 0,
            focus_agenda_index : 0,
            focus_minute_index : 0,
            participates_obj : [],
            meeting_data : JSON.parse(sessionStorage.getItem('meet_details')),
            meeting_agendas : sessionStorage.getItem('agenda_details') ? JSON.parse(sessionStorage.getItem('agenda_details')) : [],
            meeting_popup : false,
            total_current_actions : 0,
            total_current_risks : 0,
            total_current_cr : 0,
            total_current_issue : 0,
            total_current_decision : 0,
            total_current_notes : 0,
            active_tab_index : 0,
            edit_meeting_obj:"",
            meeting_all_minutes:'',
            search_input : '',
            search : '',
            tab_index_changed:false,
            all_groups : [],
            group_selected: true,
        }
    },

    async created(){
        await this.$store.dispatch('getOrgMembers',[this.$store.state.api, this.$store.state.org_id]);  
        sessionStorage.removeItem('Action_filter');
        sessionStorage.removeItem('Risk_filter');
        sessionStorage.removeItem('Change Request_filter');
        sessionStorage.removeItem('Issue_filter');
        sessionStorage.removeItem('Decision_filter');  
    },

    computed:{
        all_current_actions(){
            return this.total_current_actions;
        },

        all_current_risks(){
            return this.total_current_risks;
        },

        all_current_cr(){
            return this.total_current_cr;
        },

        all_current_issue(){
            return this.total_current_issue;
        },

        all_current_decision(){
            return this.total_current_decision;
        },

        all_current_notes(){
            return this.total_current_notes;
        },

        agenda_obj(){
            return this.meeting_agendas;
        },

        focus_index(){
           return this.focus_agenda_index;
        }
    },

    watch:{
        meeting_agendas:{
            handler(){
                this.current_minutes_type_count();
            },
            deep: true
        },

    },

    async  mounted(){
        if(this.meeting_data.meeting.meeting_title == ''){
            this.update_meeting({srcElement:{innerText:''}},"title");
        }
    },

    methods: {
        tabActivated(newTabIndex,prevTabIndex){
            if(prevTabIndex == 0 && newTabIndex >0 ){
                this.tab_index_changed = true;
            }
        },
        
        toast(variant, title, message, append = false) {
            this.counter++
            this.$bvToast.toast(message, {
              title: title,
              solid: true,
              variant : variant,
              appendToast: append
            })
        },

        current_minutes_type_count(){
            this.total_current_actions = 0;
            this.total_current_risks = 0;
            this.total_current_cr = 0;
            this.total_current_issue = 0;
            this.total_current_decision = 0;
            this.total_current_notes = 0;

            let  instant_minutes = JSON.parse(sessionStorage.getItem('agenda_details'));
        
            Object.values(this.Constants.TASK_TYPE).forEach((type) =>{
               
                instant_minutes.forEach((agenda) => {

                    let minute_indexes = agenda.minutes.reduce((a, e, i) => {
                        if (e.minute_type == type)
                            a.push(i);
                            return a;
                    }, []);

                    switch(type){
                        case this.Constants.TASK_TYPE.TYPE_1:
                            this.total_current_actions += minute_indexes.length;
                            break;
                        case this.Constants.TASK_TYPE.TYPE_2:
                            this.total_current_cr += minute_indexes.length;
                            break;
                        case this.Constants.TASK_TYPE.TYPE_3:
                            this.total_current_decision += minute_indexes.length;
                            break;
                        case this.Constants.TASK_TYPE.TYPE_4:
                            this.total_current_issue += minute_indexes.length;
                            break;
                        case this.Constants.TASK_TYPE.TYPE_5:
                            this.total_current_notes += minute_indexes.length;
                            break;
                        case this.Constants.TASK_TYPE.TYPE_6:
                            this.total_current_risks += minute_indexes.length;
                            break;
                    }

                });

            })
           
        },

        agenda_update_emit(agenda_obj){
            this.meeting_agendas = agenda_obj;
            sessionStorage.setItem('agenda_details', JSON.stringify(this.meeting_agendas));
        },

        async agenda_count_change(type,index=''){

            if(this.active_tab_index != 0){
                this.active_tab_index = 0;
            }

            this.meeting_agendas = JSON.parse(sessionStorage.getItem('agenda_details'));

            if(type == "add"){
                let agendaObj = {
                    "meeting_id":this.meeting_data.meeting._id,
                        "agenda":{
                            "operation_type":1
                        }
                    }

                await this.$store.dispatch('saveAgendaMeeting',[this.$store.state.api,agendaObj]);
                
                if(Object.keys(this.$store.state.added_agenda_data).length > 0){
                    let minutes_obj = {
                            "meeting_id": this.meeting_data.meeting._id,
                            "minutes":{
                                "agenda_id": this.$store.state.added_agenda_data.agenda._id,
                                "operation_type":1,
                                "minute_type": this.Constants.TASK_TYPE.TYPE_5,
                                "parameters" : {
                                    "asignee_id" : "",
                                    "due_date": moment().format("YYYY-MM-DD")
                                }
                            }
                        }

                    this.meeting_agendas.push(this.$store.state.added_agenda_data.agenda);

                    await this.$store.dispatch('saveAgendaMeeting',[this.$store.state.api,minutes_obj]);

                    if(Object.keys(this.$store.state.added_agenda_data).length > 0){
                        this.meeting_agendas[this.meeting_agendas.length-1].minutes=[this.$store.state.added_agenda_data.minutes];
                        this.$forceUpdate();
                    }
                }

                this.focus_agenda_index = this.meeting_agendas.length-1;
               
            }else{
                let agendaObj = {
                    "meeting_id":this.meeting_data.meeting._id,
                        "agenda":{
                            "id":this.meeting_agendas[index]._id,
                            "operation_type":3
                        }
                    }

                await this.$store.dispatch('saveAgendaMeeting',[this.$store.state.api,agendaObj]);
                this.meeting_agendas.splice(index,1);
            }

            sessionStorage.setItem('agenda_details', JSON.stringify(this.meeting_agendas));
            this.meeting_agendas = JSON.parse(sessionStorage.getItem('agenda_details'))
            this.meeting_popup = false;
        
        },

        async instant_meet_count_change(index,data_index){

            this.meeting_agendas = JSON.parse(sessionStorage.getItem('agenda_details'))

            if(index== 'null'){
                index = this.hover_index;
            }

            let delete_obj = {
                "meeting_id":this.meeting_agendas[index].meeting_id,
                "minutes":{
                    "id":this.meeting_agendas[index].minutes[data_index]._id,
                    "operation_type":3
                }
            }

            this.meeting_agendas[index].minutes.splice(data_index,1);
            await this.$store.dispatch('saveAgendaMeeting',[this.$store.state.api,delete_obj]);
            this.focus_minute_index = data_index-1;
            this.$forceUpdate();
            sessionStorage.setItem('agenda_details', JSON.stringify(this.meeting_agendas));
            this.meeting_agendas = JSON.parse(sessionStorage.getItem('agenda_details'));
            this.meeting_popup = false;
        },

        async update_meeting(e,edit_type){
            let meeting_obj = {};
            if(edit_type == 'title'){
                let meet_title = e.srcElement.innerText != '' ? e.srcElement.innerText : "Instant Meeting - "+ moment(this.meeting_data.meeting.meeting_start_time).utc().tz(this.meeting_data.meeting.meeting_timezone).format("DD MMM  - h:mmA");
                meeting_obj = {
                                "id":this.meeting_data.meeting._id,
                                "meeting_title":  meet_title     
                              }
            }else{
                meeting_obj = {
                    "id":this.meeting_data.meeting._id,
                    "meeting_objective": e.srcElement.innerHTML     
                }
            }
            await this.$store.dispatch('CrudMeeting',[this.$store.state.api, meeting_obj]);

            if(Object.keys(this.$store.state.meeting_details).length > 0){
                this.meeting_data.meeting = this.$store.state.meeting_details;
                this.$forceUpdate();
                sessionStorage.setItem('meet_details', JSON.stringify(this.meeting_data));
            }
        },

        async publish_meeting(type){
            if(type!='Discard'){
                let meeting_details = JSON.parse(sessionStorage.getItem('meet_details'))
                let meeting_obj = {
                    "id": meeting_details.meeting._id,
                    "status": type,
                    "local_timezone": this.Constants.MEET_TIME_ZONE,
                    "meeting_end_time" : moment().format("YYYY-MM-DD HH:mm")
                }

                await this.$store.dispatch('CrudMeeting',[this.$store.state.api, meeting_obj]);
            }
            sessionStorage.removeItem('meet_details');
            sessionStorage.removeItem('agenda_details');
            sessionStorage.removeItem('selected_members');
            sessionStorage.removeItem('all_participates');
            sessionStorage.removeItem('members_attendance');
            this.$router.push('/workbench');
        },

        confirm_popup_function(type,content_type=''){
            switch(type){
                case 'publish':
                    this.publish_meeting('Published');
                    break;
                case 'draft':
                    this.publish_meeting('Draft');
                    break;
                case 'minute':
                    this.instant_meet_count_change(this.hover_index,this.minutes_index);
                    break;
                case 'agenda':
                    this.agenda_count_change('delete',this.hover_index)
                    break;
                case 'popup_content':
                    this.status_change(content_type)
                    break;
                case 'discard' :
                    this.$store.commit("deleteMeeting",{"meeting_id":JSON.parse(sessionStorage.getItem('meet_details')).meeting._id});
                    this.publish_meeting('Discard')
            }
        },

        remove_meeting_pop_close(){
            this.meeting_popup = false; 
        },

        open_popup_type_function(value){
            this.group_selected = true;
            if(value == 'save_meeting_popup'){
                let meeting_details = JSON.parse(sessionStorage.getItem('meet_details'))
               
                if(meeting_details.meeting.group_id){
                    this.open_popup_type = "close_meeting_popup"; 
                    this.meeting_popup = true; 
                   
                }else{
                    this.group_selected = false;
                    this.meeting_popup = false; 
                }
               
            }else if(value[0] == 'delete_minute'){
                this.open_popup_type = "delete_popup_single_row_minutes";
                if(value[3] > 1){
                    this.meeting_popup = true;
                }
            }else if(value[0] == 'delete_agenda'){
                this.open_popup_type = "delete_popup_agenda_minutes";
                this.meeting_popup = true;
            }else if(value[0] == 'add_group'){
                this.open_popup_type = 'view_group';
                this.meeting_popup = true;
            }

            if(value != 'save_meeting_popup'){
                this.hover_index = value[1];
                this.minutes_index = value[2];
            }

            if(value[0] == 'description_comments_popup' ){
                this.open_popup_type    = "meeting_add_edit_popup";
                this.edit_meeting_obj   =  value[1];
                this.meeting_popup = true;
            }
        },

        async add_group(val){
            let group_obj = {
                    "org_id": this.$store.state.org_id,     
                    "group_name": val[1],     
                }

            await this.$store.dispatch('SaveGroup',[this.$store.state.api, group_obj]);
           
            await this.$store.dispatch('getGroups',[this.$store.state.api,{"org_id" : this.$store.state.org_id}]);
            this.all_groups = this.$store.state.groups_obj;
            this.meeting_popup = false;
            this.toast('info','Success','Added group successfully'); 
        },

        async status_change(content_type){ 
            let  minute_obj = {
                "meeting_id": this.edit_meeting_obj.meeting_id,
                "minutes":{
                    "operation_type" : 2,
                    "id" : this.edit_meeting_obj.minute_id, 
                    "minute_type": this.edit_meeting_obj.minute_type,
                }    
            }
            if(this.edit_meeting_obj.title == 'Description'){
                Object.assign(minute_obj.minutes, {"description": content_type});
            }else{
                Object.assign(minute_obj.minutes, {"comments": content_type});
            }

            if(this.edit_meeting_obj.meeting_id != JSON.parse(sessionStorage.getItem('meet_details')).meeting._id){
                Object.assign(minute_obj.minutes, {"change_log_details": { "meeting_id": sessionStorage.getItem('meet_details') ? JSON.parse(sessionStorage.getItem('meet_details')).meeting._id : this.edit_meeting_obj.meeting_id,"changed_by": this.$store.state.organiser_id,}});
            }

            await this.$store.dispatch('saveAgendaMeeting',[this.$store.state.api,minute_obj]);
        
            this.meeting_all_minutes = this.$store.state.all_meeting_minutes;

            if(this.edit_meeting_obj.meeting_id == JSON.parse(sessionStorage.getItem('meet_details')).meeting._id){

                this.meeting_agendas = JSON.parse(sessionStorage.getItem('agenda_details'))
                this.meeting_agendas.forEach((agenda)=>{
                    agenda.minutes.filter((item ,index)=>{
                        if(item._id == this.$store.state.added_agenda_data._id){
                            agenda.minutes[index].description = this.$store.state.added_agenda_data.description;
                            agenda.minutes[index].comments = this.$store.state.added_agenda_data.comments;
                        }
                    }) 
                })

                this.$forceUpdate();
                sessionStorage.setItem('agenda_details', JSON.stringify(this.meeting_agendas));
            }

            if(this.edit_meeting_obj.title == 'Description'){
                this.toast('info','Success','Added description successfully');
            }else{
                this.toast('info','Success','Added comment successfully');
            } 
            this.meeting_popup = false; 
        },

        save_agenda_details(){ 
            this.meeting_agendas = JSON.parse(sessionStorage.getItem('agenda_details'))
            this.meeting_agendas.forEach((agenda)=>{
                agenda.minutes.filter((item,index)=>{
                    if(item._id == this.$store.state.added_agenda_data._id){
                        let member_index = this.$store.state.organisation_members.findIndex( member => member._id == this.$store.state.added_agenda_data.asignee_id);
                        if(member_index >= 0){
                            agenda.minutes[index].parameters.asignee_id.full_name = this.$store.state.organisation_members[member_index].full_name;
                            agenda.minutes[index].parameters.asignee_id.email     = this.$store.state.organisation_members[member_index].email ;
                            agenda.minutes[index].parameters.asignee_id.is_guest  = this.$store.state.organisation_members[member_index].is_guest ;
                            agenda.minutes[index].parameters.asignee_id.is_organiser  = this.$store.state.organisation_members[member_index].is_organiser ;
                            agenda.minutes[index].parameters.asignee_id.org_member_id  = this.$store.state.organisation_members[member_index]._id ;

                        }
                        agenda.minutes[index].parameters.priority = this.$store.state.added_agenda_data.parameters.priority
                        agenda.minutes[index].parameters.status = this.$store.state.added_agenda_data.parameters.status
                        agenda.minutes[index].parameters.due_date = this.$store.state.added_agenda_data.parameters.due_date
                    }
                }) 
            })

            this.$forceUpdate();
            sessionStorage.setItem('agenda_details', JSON.stringify(this.meeting_agendas));
 
        },
    },

    filters:{
        time_12hr(date_time,time_zone){
            return moment(date_time).utc().tz(time_zone).format("hh:mm A");
        },

        day_month_date(date_time,time_zone){
            return moment(date_time).utc().tz(time_zone).format("ddd MMM DD, YYYY");
        },

        regular_format(date){
            return moment(date ,'YYYY-MM-DD HH:mm:ss').format("DD MMM, YYYY");
        },
    }
}
  