import moment from 'moment';
import Multiselect from 'vue-multiselect';
import Loader from '@/components/Loaders/loader_component.vue';
import InfiniteLoading from 'vue-infinite-loading';
import { bus } from '../../main';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default{
    name : 'ActionsComponent',
    components:{
        Multiselect,
        Loader,
        InfiniteLoading,
        DateRangePicker
    },
    data(){
        return{
            today_date : moment().format(),
            instant_participants:  JSON.parse(sessionStorage.getItem('all_participates')),
            issue_obj : [this.Constants.PRIORITY.TYPE_1,this.Constants.PRIORITY.TYPE_2,this.Constants.PRIORITY.TYPE_3,this.Constants.PRIORITY.TYPE_4],
            instant_meeting_obj : {},
            minute_type_obj : ['current','pending','closed'],
            filter_meetings_list : [],
            page : 1,
            minute_type_index : 0,
            filter_meeting_obj : {},
            minute_order : -1,
            org_members : [],
            members_selected : [],
            assigne_filter_dropdown : false,
            status_filter_dropdown : false,
            priority_filter_dropdown : false,
            meetname_filter_dropdown : false,
            duedate_filter_dropdown : false,
            status_obj : this.Constants.MIN_STATUS_TYPE,
            status_selected : [],
            priority_obj : [this.Constants.SELECT_ALL,this.Constants.PRIORITY.TYPE_1,this.Constants.PRIORITY.TYPE_2,this.Constants.PRIORITY.TYPE_3,this.Constants.PRIORITY.TYPE_4],
            priority_selected : [],
            all_meetings : [],
            meetings_selected : [],
            dueDateRange :{"startDate":moment(new Date(), "DD-MM-YYYY").format('MMM D, YYYY'),"endDate":moment(new Date(), "DD-MM-YYYY").add(5, 'days').format('MMM D, YYYY')},
            due_start : moment(new Date(), "DD-MM-YYYY").format('MMM D, YYYY'),
            due_end : moment(new Date(), "DD-MM-YYYY").add(5, 'days').format('MMM D, YYYY'),
            due_date_order : -1,
            mount_page : true,
            session_data : false,
        }
    },

    props: ['all_meeting_minutes','mintype','paticipantstoactions','search_text'],

    watch:{
        all_meeting_minutes:{
            handler(){
                this.reset_infinite_state();    
            },
            deep: true
        },

        instant_participants(){
            this.reset_infinite_state();
        },

        search_text(){
            this.reset_infinite_state();
        },

        minute_order(){
            if(!this.session_data){
                this.reset_infinite_state();
            }
        },

        due_date_order(){
            this.duedate_filter_dropdown = false;
            if(!this.session_data){
                this.reset_infinite_state();
            }
        },
    },

    async mounted(){ 
        if(this.$refs.action_date_picker){
            this.$refs.action_date_picker[0].focus();
            this.$refs.action_date_picker[0].blur();
        }

        document.addEventListener('click', this.documentClick);

        let index = this.status_obj.indexOf(this.Constants.SELECT_ALL);
        if(index == -1){
            this.status_obj.splice(0, 0, this.Constants.SELECT_ALL);
        }
        
        await this.get_organisation_members();
        await this.get_all_meetings();
    },

    beforeDestroy() {
        document.removeEventListener('click', this.documentClick);
    },
     
    directives:{
        default_format:{ 
            update(el){
                let original_date_input = el.value;
                if(el.value ==''){
                    el.value = moment().add(1, 'days').format("DD MMM YYYY");
                }else if(moment(el.value).format("DD MMM YYYY") == "Invalid date"){
                    el.value = moment(el.value, "DD MM YYYY").format("DD MMM YYYY")
                }else{
                    el.value = moment(el.value).format("DD MMM YYYY");
                }

                if(original_date_input.match(/[a-zA-Z]/) != null){
                    if(!Number.isInteger(parseInt(original_date_input.slice(-2)))){
                        el.value = moment(original_date_input, "DD MMM YYYY").format("DD MMM YYYY")
                    }else if(!Number.isInteger(parseInt(original_date_input.split("").reverse().join("").slice(-2)))){
                        el.value = moment(original_date_input, "MMM DD YYYY").format("DD MMM YYYY")
                    }

                    if(Number.isInteger(parseInt(original_date_input.slice(-2))) || Number.isInteger(parseInt(original_date_input.slice(-1)))){

                        if(Number.isInteger(parseInt(original_date_input.slice(-4)))){
                            el.value = moment(original_date_input).format('DD MMM YYYY');
                        }else{
                            el.value = moment(moment(original_date_input,'MMM DD').toString()).format("DD MMM YYYY");
                        }
                        
                    }else if(Number.isInteger(parseInt(original_date_input.split("").reverse().join("").slice(-2)))){
                        el.value = moment(original_date_input, "DD MMM YYYY").format("DD MMM YYYY")
                    }

                }else if(original_date_input.replace(/\D/g, "").length <= 4 && original_date_input != ""){
                    el.value = moment(original_date_input, "DD MM YYYY").format("DD MMM YYYY")

                    if(el.value == "Invalid date"){
                        el.value = moment(original_date_input, "MM DD YYYY").format("DD MMM YYYY")
                    }
                }
            },   
        },

    },

    created(){
        bus.$on('paticipantstoactions', (data) => {
            this.instant_participants = data;
        })
    },

    methods:{

        documentClick(event){
            var isClickInsideAssignElement =  document.getElementById('assigne_head').contains(event.target);

            var isClickInsideStatusElement =  document.getElementById('status_head').contains(event.target);

            var isClickInsideMeetElement =  document.getElementById('meeting_head').contains(event.target);

            if(this.mintype != this.Constants.TASK_TYPE.TYPE_2 && this.mintype != this.Constants.TASK_TYPE.TYPE_3){
                var isClickInsideDateElement =  document.getElementById('date_head').contains(event.target);
            }

            if(this.mintype == this.Constants.TASK_TYPE.TYPE_4){
                var isClickInsidePriorityElement =  document.getElementById('priority_head').contains(event.target);
            }
            
            if(!isClickInsideAssignElement && this.assigne_filter_dropdown){
                this.assigne_filter_dropdown = false;
            }else if(!isClickInsideStatusElement && this.status_filter_dropdown){
                this.status_filter_dropdown = false
            }else if(this.mintype == this.Constants.TASK_TYPE.TYPE_4 && !isClickInsidePriorityElement && this.priority_filter_dropdown){
                this.priority_filter_dropdown = false;
            }else if(!isClickInsideMeetElement && this.meetname_filter_dropdown){
                this.meetname_filter_dropdown = false;
            }else if(!isClickInsideDateElement && this.duedate_filter_dropdown && this.mintype != this.Constants.TASK_TYPE.TYPE_2 && this.mintype != this.Constants.TASK_TYPE.TYPE_3){
                this.duedate_filter_dropdown = false;
            }
        },

        blur_input(e){
            if(e.keyCode === 13){
                e.target.blur(); 
            }
        },

        reset_infinite_state(){
            this.mount_page = false;
            this.minute_type_index = 0;
            this.page = 1;
            this.filter_meetings_list = [];
            this.$nextTick(() => {
                this.$refs.infiniteLoading.stateChanger.reset()
            })
        },

        select_status(e){
            if(e.target.value == this.Constants.SELECT_ALL && e.target.checked){
                this.status_selected = this.status_obj;
            }else if(e.target.value == this.Constants.SELECT_ALL && !e.target.checked){
                this.status_selected = [];
            }else if(this.status_selected.length != this.status_obj.length){
                let all_index = this.status_selected.findIndex(item => item == this.Constants.SELECT_ALL);
                if(all_index > -1){
                    this.status_selected.splice(all_index,1);
                }else if(this.status_selected.length == this.status_obj.length-1){
                    this.status_selected.push(this.Constants.SELECT_ALL)
                }
            }
            this.reset_infinite_state();
        },

        select_priority(e){
            if(e.target.value == this.Constants.SELECT_ALL && e.target.checked){
                this.priority_selected = this.priority_obj;
            }else if(e.target.value == this.Constants.SELECT_ALL && !e.target.checked){
                this.priority_selected = [];
            }else if(e.target.checked && this.priority_selected.length == this.priority_obj.length-1){
                let index = this.priority_selected.indexOf(this.Constants.SELECT_ALL);
                if(index == -1){
                    this.priority_selected.push(this.Constants.SELECT_ALL);
                }
            }else if(this.priority_selected.length != this.priority_obj.length){
                let all_index = this.priority_selected.findIndex(item => item == this.Constants.SELECT_ALL);
                if(all_index > -1){
                    this.priority_selected.splice(all_index,1);
                }else if(this.priority_selected.length == this.priority_obj.length-1){
                    this.priority_selected.push(this.Constants.SELECT_ALL)
                }
            }
            this.reset_infinite_state();
        },

        select_assigne(e) {
            if(e.target.value == 0 && e.target.checked){
                this.members_selected = [];
                this.org_members.forEach(member => {
                    this.members_selected.push(member._id);
                })
            }else if(e.target.value == 0 && !e.target.checked){
                this.members_selected = [];
            }else if(this.members_selected.length != this.org_members.length){
                let all_index = this.members_selected.findIndex(item => item == 0);
                if(all_index > -1){
                    this.members_selected.splice(all_index,1);
                }else if(this.members_selected.length == this.org_members.length-1){
                    this.members_selected.push(0)
                }
            }
            this.reset_infinite_state();
        },

        select_meeting(e){
            if(e.target.value == 0 && e.target.checked){
                this.meetings_selected = [];
                this.all_meetings.forEach(member => {
                    this.meetings_selected.push(member._id);
                })
            }else if(e.target.value == 0 && !e.target.checked){
                this.meetings_selected = [];
            }else if(this.meetings_selected.length != this.all_meetings.length){
                let all_index = this.meetings_selected.findIndex(item => item == 0);
                if(all_index > -1){
                    this.meetings_selected.splice(all_index,1);
                }else if(this.meetings_selected.length == this.all_meetings.length-1){
                    this.meetings_selected.push(0)
                }
            }
            this.reset_infinite_state();
        },

        select_due_range(){
            this.duedate_filter_dropdown = false;
            this.reset_infinite_state();
        },

        async get_organisation_members(){
            this.org_members = await this.$store.dispatch('getOrgMembers',[this.$store.state.api,this.$store.state.org_id]);
            this.org_members.splice(0, 0, {'_id':0,'full_name': this.Constants.SELECT_ALL});
        },

        async get_all_meetings(){
            await this.$store.dispatch('getAllMeetingsAndGroups',[this.$store.state.api,{"organiser_id" : this.$store.state.organiser_id, "org_id" : this.$store.state.org_id}]);

            this.all_meetings = this.$store.state.all_meetings_and_records.meetings;

            this.all_meetings.splice(0, 0, {'_id':0,'meeting_title': this.Constants.SELECT_ALL});
        },
        
        async get_all_minutes($state){
            this.instant_meeting_obj =  JSON.parse(sessionStorage.getItem('meet_details'));

            this.filter_meeting_obj = {
                "organiser_id": this.$store.state.organiser_id,
                "this_meeting_id": this.instant_meeting_obj.meeting._id,
                "minute_type": this.mintype,
                "page":this.page,
                "limit":10,
                "minutes" : this.minute_type_obj[this.minute_type_index],
                "filters" :{
                    "minute_id_order": this.minute_order,
                    "meeting_date_order" : this.due_date_order,
                },  
            }

            if(this.search_text != ''){
                Object.assign(this.filter_meeting_obj, {"search": this.search_text});
            }

            if(this.members_selected.length != 0){
                Object.assign(this.filter_meeting_obj.filters, {"asignee_id": this.members_selected});
            }

            if(this.status_selected.length != 0){
                Object.assign(this.filter_meeting_obj.filters, {"status": this.status_selected});
            }

            if(this.meetings_selected.length != 0){
                let all_index = this.meetings_selected.findIndex(item => item == 0);
                var meet_select = [...this.meetings_selected]
                if(all_index > -1){
                    meet_select.splice(all_index,1);
                }
                Object.assign(this.filter_meeting_obj.filters, {"meeting_id": meet_select});
            }

            if(this.priority_selected.length != 0 && this.mintype == this.Constants.TASK_TYPE.TYPE_4 && this.priority_selected.length != this.priority_obj.length){
                Object.assign(this.filter_meeting_obj.filters, {"priority": this.priority_selected});
            }

            if(this.dueDateRange != '' && this.mintype != this.Constants.TASK_TYPE.TYPE_2 && this.mintype != this.Constants.TASK_TYPE.TYPE_3){
                Object.assign(this.filter_meeting_obj.filters, {"due_date": {'from': moment(this.dueDateRange.startDate).format('YYYY-MM-DD'), 'to' : moment(this.dueDateRange.endDate).format('YYYY-MM-DD')}});
            }

            if(this.mount_page && sessionStorage.getItem(this.mintype+'_filter')){
                let session_filter = JSON.parse(sessionStorage.getItem((this.mintype+'_filter')));

                if(session_filter.meeting_id){
                    this.meetings_selected = [...session_filter.meeting_id];
                    if(this.$store.state.all_meetings_and_records.meetings.length-1 == session_filter.meeting_id.length){
                        this.meetings_selected.push(0);
                    }
                }    

                if(session_filter.due_date && this.mintype != this.Constants.TASK_TYPE.TYPE_2 && this.mintype != this.Constants.TASK_TYPE.TYPE_3){
                    this.dueDateRange.startDate = moment(session_filter.due_date.from).format('MMM D, YYYY');
                    this.dueDateRange.endDate = moment(session_filter.due_date.to).format('MMM D, YYYY');    
                }

                if(session_filter.meeting_date_order){
                    this.session_data = true;
                    this.due_date_order  = session_filter.meeting_date_order;
                }

                if(session_filter.minute_id_order){
                    this.session_data = true;
                    this.minute_order = session_filter.minute_id_order;
                }

                if(session_filter.asignee_id){
                    this.members_selected = session_filter.asignee_id;
                }

                if(session_filter.status){
                    this.status_selected = session_filter.status;
                }

                if(session_filter.priority && this.mintype == this.Constants.TASK_TYPE.TYPE_4){
                    this.priority_selected = session_filter.priority;
                }

                this.filter_meeting_obj.filters = session_filter;

            }else if(!this.mount_page || !sessionStorage.getItem(this.mintype +'_filter')){
                sessionStorage.setItem(this.mintype +'_filter', JSON.stringify(this.filter_meeting_obj.filters) );
            }

            this.$store.state.loader_popup = true;
            await this.$store.dispatch('getMeetingAllMinutes', [this.$store.state.api, this.filter_meeting_obj]);
            this.$store.state.loader_popup = false;

            this.session_data = false;

            if(this.$store.state.all_meeting_minutes.length != 0) {
                this.filter_meetings_list.push(...this.$store.state.all_meeting_minutes);
                if($state){
                    this.page ++;  
                    $state.loaded();
                }else{
                    $state.complete();
                }
            }else{
                this.page = 1;
                this.minute_type_index += 1;
                if(this.minute_type_index <= this.minute_type_obj.length-1){
                    this.filter_meetings_list.push({'minute_type_title' : this.minute_type_obj[this.minute_type_index]})
                    this.get_all_minutes($state);   
                }else{
                    $state.complete();
                }
            }
        },

        async status_change(parameters,meeting_id,minute_id,minute_type,assigne_id = ''){ 

            let date = parameters.due_date;
            if(date==''){
                date = moment().add(1, 'days').format("YYYY-MM-DD");
            }else if(moment(date).format("DD MMM YYYY") == "Invalid date"){
                date = moment(date, "DD MM YYYY").format("YYYY-MM-DD")
            }else{
                date = moment(date).format("YYYY-MM-DD");
            }

            if(date.match(/[a-zA-Z]/) != null){
          
                if(!Number.isInteger(parseInt(date.slice(-2)))){
                    date = moment(date, "DD MMM YYYY").format("YYYY-MM-DD")
                }else if(!Number.isInteger(parseInt(date.split("").reverse().join("").slice(-2)))){
                    date = moment(date, "MMM DD YYYY").format("YYYY-MM-DD")
                }

                if(Number.isInteger(parseInt(date.slice(-2))) || Number.isInteger(parseInt(date.slice(-1)))){

                    if(Number.isInteger(parseInt(date.slice(-4)))){
                        date = moment(date).format('YYYY-MM-DD')
                    }else{
                        date = moment(moment(date,'MMM DD').toString()).format("YYYY-MM-DD");
                    }
                    
                }else if(Number.isInteger(parseInt(date.split("").reverse().join("").slice(-2)))){
                    date = moment(date, "DD MMM YYYY").format("YYYY-MM-DD")
                }

            }else if(date.replace(/\D/g, "").length <= 4 && date != ""){
                date = moment(date, "DD MM YYYY").format("YYYY-MM-DD")

                if(date == "Invalid date"){
                    date = moment(date, "MM DD YYYY").format("DD MMM YYYY")
                }
            }

            let minute_obj = {
                "meeting_id": meeting_id,
                "minutes":{
                    "operation_type" : 2,
                    "id" : minute_id, 
                    "minute_type": minute_type,
                    "parameters":{
                        "asignee_id" : assigne_id ? assigne_id.org_member_id._id : parameters.asignee_id,
                        "due_date": date,
                        "status":   parameters.status,
                        "priority": parameters.priority,
                    },
                   
                }
            }

            if(meeting_id != this.instant_meeting_obj.meeting._id){
                Object.assign(minute_obj.minutes, {"change_log_details": { "meeting_id": sessionStorage.getItem('meet_details') ? JSON.parse(sessionStorage.getItem('meet_details')).meeting._id : meeting_id,"changed_by": this.$store.state.organiser_id,}});
            }

            await this.$store.dispatch('saveAgendaMeeting',[this.$store.state.api,minute_obj]);
            this.reset_infinite_state();
            this.$forceUpdate();

            if(meeting_id == this.instant_meeting_obj.meeting._id){
               this.$emit('save_agenda')
            }    
        },

        view_meeting(meeting_id){
            this.$router.push('viewminutes/'+meeting_id);
        },

        open_popup(meeting_id,minute_id,description,minute_type,title){
            let meeting_obj = {
                "meeting_id":meeting_id,
                "minute_id" :minute_id,
                "description" :description,
                "minute_type":minute_type,
                "title"      :title,
            }
            this.$emit('open_popup',['description_comments_popup',meeting_obj]);
        },
    },

    filters:{
        content_manage(content){
            content =  content.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ').replace(/&nbsp;/,' ');

            if(content.length > 65){
                return content.substr(0, 65) + '...';
            }else{
                return content
            }
        },

        regular_format(date){
            return moment(date).format("DD MMM, YYYY");
        },

        min_type_abr(min_type){
            return min_type.substr(0,1);
        },

        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    }
}
