import {mapGetters} from 'vuex';
import { bus } from '../../main'
import Multiselect from 'vue-multiselect';

export default{
    name : 'SearchMemberComponent',
    components:{
        Multiselect
    },
    data(){
        return{
            search : '',
            add_mem_firstname : '',
            add_mem_lastname : '',
            add_mem_mail : '',
            members_selected: [],
            meeting_participates: JSON.parse(sessionStorage.getItem('all_participates')),
            participates_attended :sessionStorage.getItem('members_attendance') ? JSON.parse(sessionStorage.getItem('members_attendance')) : [],
            add_member_select : sessionStorage.getItem('selected_members') ? JSON.parse(sessionStorage.getItem('selected_members')) :   [],
            add_member_error : '',
            meeting_data : JSON.parse(sessionStorage.getItem('meet_details')),
            all_groups : [],
            meet_group : '',
            select_grp_error : false,
        }
    },

    props: {
        participates : Array,
        groups_obj : Array,
        grp_select : Boolean,
    },

    watch:{
        groups_obj(val){
            this.all_groups = val;
            let added_grp_index = this.all_groups.findIndex(grp => grp._id == this.$store.state.save_group_data._id);
            this.meet_group = this.all_groups[added_grp_index];
            this.save_meeting_group();
        },

        grp_select(val){
            if(!val){
                this.$refs.group_ref.isOpen = true;
                this.select_grp_error = true;
            }
        }
    },

    async mounted(){
        await this.$store.dispatch('getGroups',[this.$store.state.api,{"org_id" : this.$store.state.org_id}]);

        this.all_groups = this.$store.state.groups_obj;
        this.participatesToParent();

        if(this.meeting_data.meeting.group_id != ''){
            let group_index = this.all_groups.findIndex(group => group._id == this.meeting_data.meeting.group_id);
            if(group_index > -1){
                this.meet_group = this.all_groups[group_index];
            }
        }
    },

    computed:{
        ...mapGetters({search_member_result :'search_org_members',mail_exist_in_members :'mail_exist_in_members'}),

        filteredData(){
            if(this.search.length > 0){
                return this.search_member_result(this.search);
            }
        },
    },

    methods: {
        toast(variant, title, message, append = false) {
            this.counter++
            this.$bvToast.toast(message, {
              title: title,
              solid: true,
              variant : variant,
              appendToast: append
            })
        },

        async add_participate(e){
            this.$store.state.loader_popup = true;

            if(e.target.checked){
                let add_participate_data = {
                    "meeting_id":this.meeting_data.agenda.meeting_id,
                    "org_member_id": e.target.value
                }

                await this.$store.dispatch('addParticipatestoMeeting',[this.$store.state.api,add_participate_data]);

                if(Object.keys(this.$store.state.added_participate).length > 0){
                    let member_index = this.$store.state.organisation_members.findIndex(member => member._id == this.$store.state.added_participate.org_member_id);
                    if(member_index >= 0){
                        this.$store.state.added_participate.full_name = this.$store.state.organisation_members[member_index].full_name ;
                        this.$store.state.added_participate.html_name = this.$store.state.organisation_members[member_index].html_name ;
                        this.$store.state.added_participate.email = this.$store.state.organisation_members[member_index].email;
                        this.meeting_participates.push(this.$store.state.added_participate);
                        this.save_participates();
                    }
                }

                
                this.$store.commit('updateAttendance',{"participant_id": this.$store.state.added_participate._id,"attendance_status": e.target.checked});
                this.participates_attended.push(this.$store.state.added_participate._id);
                sessionStorage.setItem('members_attendance', JSON.stringify(this.participates_attended));
                this.toast('info','Success','Added participant successfully');
            }else{
               
                let member_index = this.meeting_participates.findIndex(member => member.org_member_id == e.target.value);
                await this.$store.dispatch('deleteParticipate',[this.$store.state.api,{"participant_id": this.meeting_participates[member_index]._id,"meeting_id": this.meeting_data.agenda.meeting_id}])
                if(member_index >= 0){
                    this.meeting_participates.splice(member_index,1);
                    this.save_participates();
                }
                let  index = this.participates_attended.indexOf(this.$store.state.added_participate._id);
                if (index > -1) {
                    this.participates_attended.splice(index, 1);
                }
                sessionStorage.setItem('members_attendance', JSON.stringify(this.participates_attended));
                this.toast('info','Success','Removed participant successfully');

                // dont delete
                // let agenda_obj = JSON.parse(sessionStorage.getItem('agenda_details') )

                // let value = e.target.value

                // agenda_obj.forEach((agenda,index) => {

                //     let minute_indexes = agenda.minutes.reduce((a, e, i) => {
                //         if (e.parameters.asignee_id.org_member_id == value)
                //             a.push(i);
                //             return a;
                //     }, []);

                //     if(minute_indexes.length > 0){
                //         minute_indexes.forEach(minute_index => {
                //             agenda_obj[index].minutes[minute_index].parameters.asignee_id = "";
                //         })
                //     }

                // });

                // this.$emit('agenda_minutes_update', agenda_obj);
            }
            this.member_selected();
            this.participatesToParent();
            this.$store.state.loader_popup = false;
        },

        participatesToParent() {
            bus.$emit('all_participates', this.meeting_participates);
            bus.$emit('paticipantstoactions', this.meeting_participates);
        },

        mail_exist_in_participates(mail){
            return this.meeting_participates.find(attendee => attendee.email == mail);
        },

        member_selected(){
            sessionStorage.setItem('selected_members', JSON.stringify(this.add_member_select));
        },

        save_participates(){
            sessionStorage.setItem('all_participates', JSON.stringify(this.meeting_participates));
        },

        save_attendance(e){
            this.$store.commit('updateAttendance',{"participant_id": e.target.value,"attendance_status": e.target.checked});
            sessionStorage.setItem('members_attendance', JSON.stringify(this.participates_attended));
            this.toast('info','Success','Attendance updated successfully');
        },

        async addGuest(){
            if(this.add_mem_firstname != "" && this.add_mem_lastname !="" && this.add_mem_mail){
                let add_member_data = {
                    "org_id": this.$store.state.org_id,
                    "meeting_id":this.meeting_data.agenda.meeting_id,
                    "first_name": this.add_mem_firstname,
                    "last_name": this.add_mem_lastname,
                    'full_name' : this.add_mem_firstname + ' ' + this.add_mem_lastname,
                    "email": this.add_mem_mail
                }
               
                let mail_exist_in_participates =  this.mail_exist_in_participates(this.add_mem_mail);
               
                let mail_exist_in_members =  this.mail_exist_in_members(this.add_mem_mail);

                if(!mail_exist_in_members && !mail_exist_in_participates){
                    await this.$store.dispatch('addParticipatestoMeeting',[this.$store.state.api,add_member_data]);
                    if(Object.keys(this.$store.state.added_participate).length > 0){  

                        this.$store.state.added_participate.full_name = this.add_mem_firstname + ' ' + this.add_mem_lastname;
                        this.$store.state.added_participate.email = this.add_mem_mail;
                        this.$store.state.added_participate.html_name = "<span>"+this.add_mem_firstname + ' ' + this.add_mem_lastname+"&nbsp;</span><br>"+"<small class='grey'>"+this.add_mem_mail+"</small>";
                        this.meeting_participates.push(this.$store.state.added_participate);
                        this.add_member_select.push(this.$store.state.added_participate.org_member_id);

                        this.save_participates();
                        this.member_selected();
                    }
                    this.$store.commit('updateAttendance',{"participant_id": this.$store.state.added_participate._id,"attendance_status": true});
                    this.participates_attended.push(this.$store.state.added_participate._id);
                    sessionStorage.setItem('members_attendance', JSON.stringify(this.participates_attended));
                    this.toast('info','Success','Guest added successfully');
                    this.add_member_error = "";
                    this.add_mem_firstname = "";
                    this.add_mem_lastname = "";
                    this.add_mem_mail = "";
                   
                    await this.$store.dispatch('getOrgMembers',[this.$store.state.api,this.$store.state.org_id ]);
                    this.participatesToParent();

                }else if(mail_exist_in_participates && mail_exist_in_participates.email){
                    this.add_member_error = "Member already  exists";
                    return false;
                }else{
                    this.add_member_error = "Member already exists";
                    return false;
                }
            }
        },

        async save_meeting_group(){
            let meeting_details = JSON.parse(sessionStorage.getItem('meet_details'))
            let meeting_obj = {
                "id": meeting_details.meeting._id,
                "group_id": this.meet_group._id,
                "group_name": this.meet_group.group_name,
            }
            await this.$store.dispatch('CrudMeeting',[this.$store.state.api, meeting_obj]);
            meeting_details.meeting.group_name = this.meet_group.group_name;
            meeting_details.meeting.group_id = this.meet_group._id;
            this.$forceUpdate();
            if(Object.keys(this.$store.state.meeting_details).length > 0){
                sessionStorage.setItem('meet_details', JSON.stringify(meeting_details));
            }
            this.select_grp_error = false;
            this.$emit('group_select_status');
        },

        close_meeting(){
            this.$emit('open_popup','save_meeting_popup');
        },

    }
}